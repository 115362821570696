import React, { useState, useEffect } from 'react';
import { LightBulbIcon } from '@heroicons/react/24/outline'; // 导入灯泡图标

const StringSwitcher = ({ strings }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % strings.length);
        }, 1500);

        return () => clearInterval(interval); // 清理定时器
    }, [strings.length]);

    return (
        <div className="flex justify-center items-center h-8 m-3 bg-[#d6064c] rounded-md bg-opacity-90">
            <h1 className="text-base text-white flex items-center">
                <LightBulbIcon className="h-6 w-6 text-yellow-500 mr-2" /> {/* 灯泡图标 */}
                {strings[currentIndex]}
            </h1>
        </div>
    );
};

export default StringSwitcher;
