import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {get} from "../http/api";

// 模拟视频数据

const VideoDetailPage = () => {
    const [video, setVideo] = useState(null);
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // 从 URL 中获取 id 参数
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');

        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await get('/video/play_paoxue/'+id); // 使用 GET 请求
                setVideo(result)
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [location]);

    if (!video) {
        return <div className="text-center text-white">加载中...</div>; // 视频未找到时的加载状态
    }

    return (
        <div className="bg-[#3C3D37] min-h-screen">
            {/* 吸顶导航 */}
            <div className="sticky top-0 bg-[#181C14] shadow-md p-4 flex items-center justify-between h-16 z-10">
                <span className="text-lg font-semibold text-white">好兄弟泡学网</span>
            </div>

            <div className="relative">
                <video
                    className="w-full rounded"
                    controls
                    src={video.video} // 视频播放地址
                    poster={video.thum} // 视频封面图
                    style={{ aspectRatio: '16/9' }} // 设置宽高比为 16:9
                >
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="my-4 text-white bg-[#343131] p-5">
                <p>{video.name}</p>
            </div>

            <div className=''>
                <div className='text-right mr-5 text-[#E9EFEC]'>赞助商广告</div>
                <div className='mt-5'>
                    <a href='https://lezi.wtf' target='_blank'>
                        <img className='w-full' src="/images/b013013c-0cab-404c-a14a-d1658b054889.gif"/>
                    </a>
                    <a className='mt-5' href='https://ke.qidianla.com/courses/tobpm' target='_blank'>
                        <img className='w-full' src="/images/23cd6d62-6a76-11ee-a068-00163e142b65.webp"/>
                    </a>
                    <a className='mt-5' href='https://ke.qidianla.com/courses/tobpm' target='_blank'>
                        <img className='w-full' src="/images/60dcb853d248e20b162288637cb3aaad5c48a78d61795-IOhj7O_fw658.webp"/>
                    </a>
                </div>
            </div>

        </div>
    );
};

export default VideoDetailPage;
