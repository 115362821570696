import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {get, post} from "../http/api"; // 导入 useNavigate

const VideoListPage = () => {
    const [channelId, setChannelId] = useState(-1);
    const [searchQuery, setSearchQuery] = useState('');
    const [channel, setChannel] = useState({});
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [nextPage, setNextPage] = useState(1);
    const navigate = useNavigate(); // 使用 useNavigate

    const location = useLocation();

    useEffect(() => {
        if(channelId === -1) return
        loadVideos();
    }, [channelId]); // 仅在 channelId 更新时执行

    const fetchChannelData = async (channel_id) => {
        setLoading(true);
        try {
            const result = await get('/channel/paoxue?channel_id='+channel_id+"&pageNum="+nextPage+"&pageSize=20"); // 使用 GET 请求
            setChannel(result)

            const newVideos = result.videos;
            if (newVideos.length > 0) {
                setVideos((prev) => [...prev, ...newVideos]); // Append new videos
                setNextPage((prev) => prev + 1);
            } else {
                setHasMore(false); // No more videos to load
            }
            setLoading(false);

            setNextPage(nextPage + 1)

        } finally {
            setLoading(false);
        }
    };

    const fetchSearchData = async (keyword) => {
        setLoading(true);
        try {
            const result = await post('/video/search_paoxue?pageNum='+nextPage, {keyword: keyword});

            const newVideos = result.records;
            if (newVideos.length > 0) {
                setVideos((prev) => [...prev, ...newVideos]); // Append new videos
                setNextPage((prev) => prev + 1);
            } else {
                setHasMore(false); // No more videos to load
            }
            setLoading(false);

            setNextPage(nextPage + 1)

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const channel = queryParams.get('channel');
        if(channel) {
            // 展示频道
            setChannelId(channel);
        } else {
            setChannelId(-1);
        }

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Cleanup the event listener
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goVideo = (id) => {
        navigate('/video?id='+id);
    }

    const loadVideos = () => {
        if (loading || !hasMore) return; // Prevent multiple loads
        setLoading(true);

        // 判断调用哪个函数
        if(channelId !== -1) {
            // 获取频道数据
            fetchChannelData(channelId);
        } else {
            // 搜索关键字
            fetchSearchData(searchQuery);
        }
    };

    const handleSearch = () => {
        setVideos([]);
        setNextPage(1);
        setHasMore(true);
        loadVideos(); // Load videos based on the search (not implemented here)
    };

    const handleScroll = () => {
        // Check if scrolled to bottom of the page
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >=
            document.documentElement.scrollHeight &&
            !loading && hasMore
        ) {
            if(nextPage !== 1) {
                loadVideos();
            }
        }
    };

    return (
        <div className="p-4 bg-[#3C3D37] min-h-screen">
            {/* Search Section */}

            {channelId === -1 && (<div className="flex justify-between items-center mb-4">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="输入搜索内容"
                    className="border border-gray-300 p-2 rounded-md flex-grow mr-2"
                />
                <button
                    onClick={handleSearch}
                    className="bg-[#D91656] text-white px-4 py-2 rounded-md"
                >
                    搜索
                </button>
            </div>)}

            {channel.name && (<div className='text-center text-white bg-[#181C14] p-3 mb-3'>{channel.name}</div>)}

            {/* Video List Section */}
            <div className="grid grid-cols-2 gap-4">
                {videos.map((video, index) => (
                    <a
                        key={index}
                        onClick={() => goVideo(video.id)}
                        className="block bg-[#343131] p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={video.thum} alt={video.name} className="w-full h-24 object-cover rounded" />
                        <p className="mt-2 text-white text-base font-medium overflow-hidden text-ellipsis line-clamp-2" title={video.name}>
                            {video.name}
                        </p>
                    </a>
                ))}
            </div>

            {/* Loading State */}
            {loading && (
                <div className="flex justify-center items-center h-48">
                    <svg
                        className="animate-spin h-10 w-10 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v16a8 8 0 01-8-8z"
                        />
                    </svg>
                </div>
            )}

            {/* Load More Button (Optional) */}
            {!loading && hasMore && (
                <button
                    onClick={loadVideos}
                    className="mt-4 w-full text-white px-4 py-2 rounded-md"
                >
                    {(!searchQuery || videos.length === 0 ) ? '' : '加载更多视频'}
                </button>
            )}

            {/* No More Videos Message */}
            {!loading && !hasMore && (
                <div className="mt-4 text-center text-white">已加载全部视频</div>
            )}
        </div>
    );
};

export default VideoListPage;
