import React, { useState, useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate
import StringSwitcher from '../component/StringSwitcher'; // 引入 Marquee 组件
import Banner from '../component/Banner'; // 引入 Banner 组件
import TabListComponent from '../component/TabListComponent'; // 引入 TabListComponent 组件
import { get } from '../http/api'; // 导入封装的 API 工具类

const IndexPage = () => {
    const texts = ["本站是纯免费学习网", "希望兄弟们学有所成", "站长QQ:3987556084"];

    const navigate = useNavigate(); // 使用 useNavigate
    const [data, setData] = useState({banners: [], tabs: []});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getHomeData();
    }, []);

    const getHomeData = () => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await get('/paoxue/home'); // 使用 GET 请求
                console.log('result:', result)
                if(result.data) {
                    setData(result.data);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    };

    const handleMoreClick = () => {
        navigate('/search');
    };

    return (
        <div className="h-screen">
            {/* 吸顶导航 */}
            <div className="sticky top-0 bg-[#181C14] shadow-md p-4 flex items-center justify-between h-16 z-10">
                <div className="flex items-center">
                    <span className="text-lg font-semibold text-white">泡妞达人.vip (paoniudaren.vip)</span>
                </div>
                <div className="flex items-center">
                    <MagnifyingGlassIcon className="h-6 w-6 text-gray-500" onClick={() => handleMoreClick()} />
                </div>
            </div>

            {/* 可滚动的内容区域 */}
            <div className="w-full overflow-auto min-h-screen scrollbar-hidden overflow-hidden bg-[#3C3D37] pb-5" >
                {/* 跑马灯组件 */}

                <a href='https://lezi.wtf' target='_blank'>
                    <img className='w-full' src="/images/b013013c-0cab-404c-a14a-d1658b054889.gif"/>
                </a>

                <StringSwitcher strings={texts} />
                <Banner banners={data.banners} />
                <TabListComponent tabs={data.tabs} />

                <div className='mt-10'>
                    <div className='text-right mr-5 text-[#E9EFEC]'>赞助商广告</div>
                    <div className='mt-5'>
                        <a className='mt-5' href='https://ke.qidianla.com/courses/tobpm' target='_blank'>
                            <img className='w-full' src="/images/23cd6d62-6a76-11ee-a068-00163e142b65.webp"/>
                        </a>
                        <a className='mt-5' href='https://www.qidian.com/' target='_blank'>
                            <img className='w-full' src="/images/60dcb853d248e20b162288637cb3aaad5c48a78d61795-IOhj7O_fw658.webp"/>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default IndexPage;
