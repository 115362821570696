import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate

const Banner = ({ banners }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate(); // 使用 useNavigate

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === banners.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // 每3秒自动切换

        return () => clearInterval(interval); // 清理定时器
    }, [banners.length]);

    const goVideo = (id) => {
        navigate('/video?id='+id);
    }

    return (
        <div className="relative h-44 overflow-hidden m-3">
            {/* 渲染所有的banner */}
            <div
                className="flex transition-transform duration-700 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {banners.map((banner, index) => (
                    <a
                        key={index}
                        href={banner.url}
                        className="min-w-full h-44 relative flex justify-center items-end bg-cover bg-center"
                        style={{ backgroundImage: `url(${banner.thum})` }}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => goVideo(banner.id)}
                    >
                        {/* 显示文本 */}
                        <p className="absolute bottom-0 w-full bg-black bg-opacity-60 text-white p-1 overflow-hidden text-ellipsis line-clamp-2">
                            {banner.name}
                        </p>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Banner;
