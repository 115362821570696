// src/api.js
import axios from 'axios';

// 创建一个 axios 实例
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // 使用环境变量
    timeout: 10000, // 设置请求超时（10秒）
    headers: {
        'Content-Type': 'application/json',
    },
});

// 请求拦截器
apiClient.interceptors.request.use(
    (config) => {
        // 可以在这里添加请求头，或者处理请求前的逻辑
        return config;
    },
    (error) => {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
apiClient.interceptors.response.use(
    (response) => {
        // 处理响应数据
        return response.data;
    },
    (error) => {
        // 处理响应错误
        console.error('API Error:', error);
        return Promise.reject(error);
    }
);

// 封装的 GET 请求
export const get = (url, params) => {
    return apiClient.get(url, { params });
};

// 封装的 POST 请求
export const post = (url, data) => {
    return apiClient.post(url, data);
};

// 封装的 PUT 请求
export const put = (url, data) => {
    return apiClient.put(url, data);
};

// 封装的 DELETE 请求
export const del = (url) => {
    return apiClient.delete(url);
};

export default apiClient;
