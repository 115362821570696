import React from 'react';

const VideoItem = React.memo(({ video, onClick }) => {
    return (
        <a
            className="block bg-[#343131] p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            target="_blank"
            onClick={onClick}
            rel="noopener noreferrer"
        >
            <img src={video.thum} alt={video.name} className="w-full h-24 object-cover rounded" />
            <p className="mt-2 text-white text-base font-medium overflow-hidden text-ellipsis line-clamp-2" title={video.name}>
                {video.name}
            </p>
        </a>
    );
});

export default VideoItem;
