import React, { useState, useRef } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import '../css/TabListComponent.css';
import VideoItem from './VideoItem'; // 导入 VideoItem 组件

const TabListComponent = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const imgCache = useRef({}); // 创建一个缓存对象

    const goChannel = (id) => {
        navigate('/search?channel=' + id);
    };

    const goVideo = (id) => {
        navigate('/video?id=' + id);
    }

    return (
        <div className="w-full">
            {tabs.length === 0 ? (
                <div className="text-gray-500 text-center">正在加载</div>
            ) : (
                <>
                    <div className="flex overflow-x-auto mb-4 scrollbar-hidden whitespace-nowrap px-3">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                className={`px-4 py-1 font-bold text-lg ${activeTab === index ? 'bg-[#D91656] text-white rounded-md' : 'text-gray-500'}`}
                                onClick={() => setActiveTab(index)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>

                    <div className="space-y-8">
                        {tabs[activeTab].channels && tabs[activeTab].channels.length > 0 ? (
                            tabs[activeTab].channels.map((channel, channelIndex) => (
                                <div key={channelIndex}>
                                    <div className='bg-[#343131] p-3 mb-4 flex justify-between items-center'>
                                        <h2 className="text-base text-white">{channel.name}</h2>
                                        <button className="text-white hover:underline flex items-center" onClick={() => goChannel(channel.id)}>
                                            <EllipsisVerticalIcon className="h-5 w-5 ml-1" />
                                        </button>
                                    </div>

                                    <div className="grid grid-cols-2 gap-4 mx-3">
                                        {channel.videos.map((video, videoIndex) => {
                                            // 如果缓存中没有该视频的图像，则加载并存储在缓存中
                                            if (!imgCache.current[video.thum]) {
                                                const img = new Image();
                                                img.src = video.thum;
                                                imgCache.current[video.thum] = img; // 存储到缓存中
                                            }
                                            return (
                                                <VideoItem
                                                    key={videoIndex}
                                                    video={video}
                                                    onClick={() => goVideo(video.id)}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-gray-500 text-center">该标签下没有频道可用</div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default TabListComponent;
