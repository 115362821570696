import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import IndexPage from './pages/index';
import VideoListPage from './pages/videoList';
import VideoDetailPage from './pages/videoDetail';
import './css/index.css'; // 引入 CSS 文件

function App() {
  return (
      <Router>
        <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="/search" element={<VideoListPage />} />
            <Route path="/video" element={<VideoDetailPage />} />
        </Routes>
      </Router>
  );
}

export default App;
